@import '../../assets/styles/variables.scss';
#listaCategorias{
    padding-top: 70px;
    padding-bottom: 70px;
    .gridCategorias{
        border-bottom: solid 1px $placeholder;
        padding-bottom: 40px;
        .categoriaItem{
            margin-bottom: 30px;
            text-align: center;
            cursor: pointer;
            figure{
                margin-bottom: 20px;
                img{
                    display: block;
                    margin: 0 auto;
                    border-radius: 12px;
                    box-shadow: 0 0 6px rgba(139,158,167,.16);
                    transition: ease-in-out all .2s;
                    max-width: 100%;
                }
            }
            &:hover{
                img{
                    box-shadow: 0 0 8px rgba(0,0,0,.15);
                }
            }
            .nombre{
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
    }
    .categoriaDestacada{
        margin-top: 70px;
        h2{
            line-height: 20px;
            margin-bottom: 10px;
        }
        figure{
            img{
                display: block;
                max-width: 100%;
                border-radius: 12px;
            }
        }
        h4{
            font-size: 16px;
            margin-bottom: 20px;
        }
        p{
            text-align: justify;
        }
    }
}

.swiper-lazy-preloader {
    border-color:$color6 !important;
    border-width: 1px;
}

.css-gyjwul-EventSourceWrapper{
    border-width: 4px !important;
}