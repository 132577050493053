// Colors light
$bg__primary: #F1F2F4
$color__text: #24292E
$color__text__light: #8E8E93
$bg__btn: #009FFF
$color_secondary: #D383E0
$bg__btn__help: #fff
$bg__btn__disabled: #8E8E93
$color__text__btn: #fff
$bg__card: #fff


// Colors dark
$bg__primary--dark: #0C0B0F
$color__text--dark: #FFFFFA
$color__text__light--dark: #8E8E93
$bg__btn--dark: #009FFF
$color_secondary--dark: #D383E0
$bg__btn__help__dark: rgba(255,255,255,.1)
$color__text__btn--dark: #fff
$bg__card_dark: rgba(255,255,255,.05)

// Theme light
.theme
  --bg__primary: #{$bg__primary}
  --color__text: #{$color__text}
  --color__text__light: #{$color__text__light}
  --bg__btn: #{$bg__btn}
  --color__secondary: #{$color_secondary}
  --bg__btn__help: #{$bg__btn__help}
  --bg__btn__disabled: #{$bg__btn__disabled}
  --color__text__btn: #{$color__text__btn}
  --bg__card: #{$bg__card}
  // Theme dark
  &--dark
    --bg__primary: #{$bg__primary--dark}
    --color__text: #{$color__text--dark}
    --color__text__light: #{$color__text__light--dark}
    --bg__btn__help: #{$bg__btn__help__dark}
    --bg__btn: #{$bg__btn--dark}
    --color__secondary: #{$color_secondary--dark}
    --bg__card:  #{$bg__card_dark}
    --color__text__btn: #{$color__text__btn--dark}
