*{
    font-family: 'Bricolage Grotesque','Open Sans' !important;
}

.ld--card{
    p{
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;
    }
    .format_text{
        justify-content: space-around;
        min-height: 160px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &.ld--smresponsive{
      @media (min-width:602px){
        padding: 25px !important;
      }
      @media (max-width:601px){
        padding: 14px !important;
      }
    }
}
.listener{
    height: 100%;
    display: flex;
    @media (min-width:601px){
      padding: 23px 0;
    }
}

.ld--title{
    text-transform: capitalize !important;
}
.bg_cont{
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: var(--bg__primary);
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    @media (min-width:999px){
      height: 635px;
    }
    .ld--title{
      line-height: 1;
      @media (min-width:601px){
        font-size: 40px;
      }
      @media (max-width:600px){
        font-size: 28px;
      }
    }
    .ld--btn{
      width: 150px;
      display: inline-flex;
      text-align: center;
      span{
        width: 100%;
      }
    }
    .mb-5{
      @media (max-width:600px){
        margin-bottom: 16px !important;
      }
    }
    .container{
      height: 100%;
      .row{
        height: 80%;
        >div{
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media (min-width:991px){
            height: 100%;
          }
          p{
            max-width: 575px;
            @media (min-width:601px){
              margin-bottom: 48px;
            }
            @media (max-width:600px){
              margin-bottom: 16px !important;
            }
           
          }
        }
      }
    }
}
.footer_vm{
  display: flex;
  justify-content: flex-end;
}
.ld--btn-shop{
  @media (min-width:769px){
    width: max-content;
  }
  @media (max-width:768px){
    text-align: center;
    width: 194px !important;
  }  
  &.border_less{
    border:none !important;
  }
  &:hover{
    opacity: 0.8;
  }
}
.h3_iplift{
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.streaming_iplift{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 20px;
}

.ld--funciones{
  .ld-mw{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 6px;
    @media (min-width:991px){
      padding-left: 24px;
      min-width: 280px;
    }
  }
  .ld-emoji{
    @media (min-width:991px){
      min-width: 80px;
      text-align: left;
    }
    @media (max-width:990px){
      position: absolute;
      top: 26px;
      right: 26px;
    }
  }
}
.nft_iplift{
  .ld--item-funcion{
    gap: 10px;
  }
  @media (max-width:800px){
    .ld--fecha{
      margin: 0 !important;
    }
  }
  img{
    width: 80px;
    height: 80px;
    background: gray;
    border-radius: 10px;
  }
  .time_iplift{
    @media (min-width:1281px){
      min-width: 200px;
    }
    @media (max-width:1400px){
      .ld--lugar{
        min-width: auto !important;
      }
    }
    @media (max-width:700px){
      max-width: 60px;
    }
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: flex-end;
    text-align: right;
    .ld--fecha{
      min-width: 80px;
    }
    .ld--ciudad{
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
    }
    .ld--recinto{
      font-size: 15px;
      line-height: 20px;
      color: var(--color__text__light);
    }
  }
}
._p{
  justify-content: center;
  align-items: center;
  text-align: center;
}
.stags{
  @media (min-width:450px){
    display: inline-flex;
    gap:6px;
  }
  @media (max-width:450px){
    .tags{
      margin-bottom: 6px;
    }
  }
}
.tags{
  display: inline-flex;
  text-align: center;
  border-radius: 10px;
  background: #8C53F6;
  align-items: center;
  padding: 0 14px;
  justify-content: center;
  margin-right: 16px;
}
.ld--header{
  font-size: 16px;
  font-weight: 500;
  >div{
    position: absolute;
    z-index: 20;
    width: 100%;
  }
  nav{
    display: flex;
    max-width: 1320px;
    margin: 0 auto;
    line-height: 60px;
    align-items: center;
    // justify-content: space-between;
    justify-content: space-between;
    position: relative;
    .rb-img{
      max-width: 100%;
      position: absolute;
      left: 0;
      top: 20px;
      z-index: 1;
      @media (min-width:1281px){
        width: 380px;
      }
      @media (max-width:1280px){
        width: 280px;
      }
      @media (max-width:990px){
        width: 151px;
        left: calc(50% - 75px);
      }
      
    }
    @media (max-width:990px){
      background: var(--bg__primary);
      width: 100%;
      padding: 0 10px;
    }
    .ico_menu{
      @media (min-width:991px){
        display: none;
      }
      fill:white;
      position: absolute;
      left: -35px;
      top: 0;
      font-size: 20px;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
    }
    .open_responsive{
      right: 0;
      .ico_menu{
        @media (max-width:1000px){
          &:before{
            content: "";
            display: inline-block;
            background: rgba(0, 0, 0, 0.4);
            width: 100vw;
            height: 100vh;
            position: fixed;
            z-index: -8;
            left: -16px;
          }
        }
      }
    }
    ul{
      display: flex;
      align-items: center;
      margin: 0;
      transition: all .5s;
      @media (min-width:991px){
        width: calc(100% - 93px);
        gap: 20px;
        justify-content: center;
      }
      @media (max-width:990px){
        position: absolute;
        height: 100vh;
        background: var(--bg__primary);
        z-index: 10;
        flex-direction: column;
        top: 0;
        right: -260px;
        width: 260px;
        justify-content: flex-start;
        gap: 10px;
     }
      li{
        list-style: none;
      }
    }
  }
  .btn_login_ajust{
    white-space: nowrap;
    justify-content: center;
    display: flex;
    @media (max-width:990px){
      position: absolute;
      top: 18px;
    }
    button{
      margin: 0px;
      font-size: 16px;
      color: white;
      font-weight: 600;
    }
  }
}
.ld--folluw-us{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3{
    color: #B7B7C2;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  ul{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 10px;
    padding: 0;
    margin-bottom: 16px;
    a{
      font-size: 20px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 38px;
      &:hover{
        opacity: .8;
      }
    }
  }
}

.btn-change-app-login {
  display: none;
}

.banner-movil,
.banner-desktop{
  position: relative;
  .follow_cont{
    position: absolute;
    bottom: 0;
    justify-content: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 901px) {
      left: calc(50% - 660px);
    }
    @media (max-width: 900px) {
      right: 0;
    }
    .hoverd{
      cursor: pointer;
      &:hover{
        ul{
          height: auto;
        }
      }
    }
    ul{
      width: 192px;
      justify-content: left;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      padding: 0;
      height: 0;
      overflow: hidden;
    }
    li{
      display: flex;
      a{
        display: flex;
        gap: 10px;
        align-items: center;
        &:hover{
          color:#8C53F6;
        }
      }
      i{
        font-size: 18px;
      }
    }
    .ld--btn-shop{
      float: right;
      border: 2px solid white;
      color: white;
      width: 140px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.2);
      font-weight: 700;
      &:hover{
        background-color: white;
        border-color: white;//rgb(140, 83, 246);
        color: rgb(140, 83, 246);
        opacity: 1 !important;
      }
      //border-color: white;//rgb(140, 83, 246);
      //color: white;//rgb(140, 83, 246);
    }
  }
}

.band_cont{
  display: flex;
    gap: 8px;
  .ig_bang{
    width: 42px;
    height: 42px;
    background: white;
    align-items: center;
    display: inline-flex;
    border-radius: 100%;
    border: 3px solid white;
  }
  .text_band{
    display: flex;
    flex-direction: column;
  }
}

.t_p_footer_cont{
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #b7b7c2;
  @media (min-width:580px){
    width: calc(100% - 186px);
    padding-left: 186px;
  }
}
.flex{
  &.gap-2{
    &.items-center{
      span{
        white-space: nowrap;
      }
    }
  }
}
.spinner-border{
  width: 30px !important;
  height: 30px !important;
  border: 0.3em solid currentcolor !important;
  border-right-color: transparent !important;
}
$perro: var(--bg__primary);
.swal2-popup{
  background-color: #242327 !important;
  color: #FFFFFA !important;
  font-size: 16px;
  .titleCode{
    font-weight: 700 !important;
    font-size: 34px !important;
  }
  .swal2-html-container{
    font-weight: 16 !important;
  }
}

.follow_cont_modal{
  ul{
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    li{
      width: 100%;
    }
    a{
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      gap:16px;
      align-items: center;
      width: 100%;
      i{
        font-size: 18px;
      }
    }
  }
}
.succes_footer{
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  a{
    color:#8C53F6;
  }
}
@import './icoms';
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4taVIGxA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4kaVIGxA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4saVIGxA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4jaVIGxA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4iaVIGxA.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4vaVIGxA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4uaVIGxA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4gaVI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

/* vietnamese */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGHInHEVA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGGInHEVA.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGIInE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}