@keyframes luka-sk-aniHorizontal {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }
  .luka-sk-anim-h {
    position: relative;
    overflow: hidden;
  }

  .luka-sk-anim-h:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    animation-name: luka-sk-aniHorizontal;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(
      to right,
      rgba(237, 237, 237, 0.2) 0%,
      rgba(250, 250, 250, 0.8) 60%,
      rgba(237, 237, 237, 0.2) 100%
    );
    background-size: 50%;
    mix-blend-mode: overlay;
  }
  #luka-sk-template-skeleton {
    /*display: block;*/
    max-width: 800px;
    overflow: hidden;
  }
  #luka-sk-template-skeleton .luka-sk-metodos {
    // margin-bottom: 60px;
    /*text-align: left;*/
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
  }
  #luka-sk-template-skeleton .luka-sk-metodos div {
    width: 33.333333%;
    max-width: 120px;
    padding: 0 5px;
  }

  #luka-sk-template-skeleton .luka-sk-metodos span {
    /*display: inline-block;*/
    display: block;
    /*margin-right: 10px;*/
    margin-bottom: 20px;
    height: 70px;
    /*width: 120px;*/
    width: 100%;
    border-radius: 2px;
  }
  .luka-sk-text {
    width: 100%;
    height: 20px;
    border: 2px;
    margin-bottom: 10px;
  }
  
  .luka-sk-inp {
    width: 100%;
    height: 50px;
    border: 2px;
    margin-bottom: 20px;
  }

  .luka-sk-bg-grey {
    background: #ededed;
  }