@import "~bootstrap/scss/bootstrap"
@import "./themes.sass"
@import url('https://fonts.googleapis.com/css2?family=Martel+Sans&family=Open+Sans:wght@200;300;400;600;700;800&display=swap')

main.App
  background-color: var(--bg__primary)
  color: var(--color__text)
  font-family: 'Bricolage Grotesque','Open Sans', sans-serif
//commons
.ld--title
  font-family: 'Martel Sans', sans-serif
  font-size: 26px
  line-height: 24px
  margin-bottom: 15px
  text-transform: uppercase

.ld--card
  background: var(--bg__card)
  border-radius: 8px
  width: 100%
  padding: 26px
  @media (max-width:1200px)
  &.centered_card
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    padding: 28.5px 26px
.ld--btn
  background: var(--bg__btn)
  color: var(--color__text__btn)
  border-radius: 4px
  overflow: hidden
  padding: 0
  border: none
  min-height: 44px
  &:disabled
    cursor: not-allowed
  &.disabled, &.agotado
    background: var(--bg__btn__disabled)

  span
    display: block
    padding: 10px 16px
    transition: all ease-in-out .2s
    font-size: 16px
    font-weight: 600
    &:hover
      background: rgba(#000,.2)

.ld--btn-shop
  display: block
  padding: 10px 16px
  transition: all ease-in-out .2s
  font-size: 16px
  border-radius: 4px
  font-weight: 600
  border: solid 1px
  border-color: var(--bg__btn)
  background: none
  color: var(--bg__btn)
  display: block
  text-decoration: none
  width: max-content
  cursor:pointer
  &:hover
    color: var(--bg__btn)

.ld--btn-help
  transition: all ease-in-out .2s
  font-size: 16px
  border-radius: 4px
  font-weight: 600
  min-width: 375px
  text-align: center
  background: none
  background: var(--bg__btn__help)
  display: block
  text-decoration: none
  width: max-content
  margin: 55px auto
  overflow: hidden
  border: solid 1px var(--bg__btn)
  span
    color: var(--bg__btn)
    display: block
    padding: 10px 16px
  &:hover
    span
      color: var(--bg__btn)
      background: rgba(#000,.2)
//commons end

.App
  overflow-y: auto
  overflow-x: hidden
  min-height: 100vh
  display: flex
  flex-direction: column

  .ld--header
    display: block
    min-height: 200px
    background-color: var(--bg__primary)
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    margin-bottom: 32px
    position: relative
    @media (min-width:1350)
      min-height: 100vh
    .responsive_logo
      @media (min-width:1001px)
        display: none
      @media (max-width:1000px)
        display: block
        padding-right: 2rem
        padding-top: 2rem
    .banner-desktop
        @media (max-width:767px)
          display: block
        @media (max-width:766px)
          display: none
    .banner-movil
        @media (min-width:767px)
          display: none
    img
      display: block
      width: 100%
      display: block
      border-radius: 0
.ld--intro
  margin-top: 32px
  margin-bottom: 32px
  img
    border-radius: 8px
  p
    font-size: 17px
    line-height: 22px

.ld--intro-images
  ul
    padding-left: 12px
    margin-bottom: 0
    li
      color: var(--color__secondary)
    span
      color: var(--color__text)
  img
    display: block
    max-width: 100%
    margin-bottom: 20px
  .row
    .col:nth-child(3),
    .col:nth-child(4)
      img
        margin-bottom: 0 !important


.ld--wrap-notas
  margin-bottom: 32px
  .ld--title
    color: var(--color__secondary)
  li
    color: var(--color__secondary)
    span
      color: var(--color__text)

.ld--wrap-funciones
  margin-bottom: 32px
  @media (max-width:767px)
    &.no_warp_r
      .ld--item-funcion
        flex-wrap: nowrap !important
        .ld--fecha
          width: 80px !important
        .ld--lugar
          width: calc(100% - 160px) !important
          min-width: auto !important
          margin-right: 0 !important
  .ld--wrap-botones-titles
    align-items: center
    .ld--monedas
      display: flex
      margin-bottom: 15px
      span
        display: block
        font-size: 14px
        font-weight: 600
        padding: 5px 10px
        border-radius: 4px
        line-height: 14px
        margin-left: 10px
        cursor: pointer
        border: solid 1px var(--color__secondary)
        color: var(--color__secondary)
        &.active
          background: var(--color__secondary)
          color: #FFF
  .ld--funciones
    .ld--item-funcion
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 18px
      position: relative
      &:last-child
        margin-bottom: 0
      .ld--lugar
        margin-right: 40px
        min-width: 194px
        display: flex
        flex-direction: column
        justify-content: flex-start
        .ld--ciudad
          font-size: 16px
          font-weight: 600
          line-height: 24px
          &._new_mar
          font-size: 16px
          font-weight: initial
          display: flex
          align-items: center
          gap: 10px
        .ld--recinto
          font-size: 15px
          line-height: 20px
          color: var(--color__text__light)
      .ld--fecha
        font-size: 15px
        line-height: 20px
        color: var(--color__text__light)

      .ld--acciones
        margin-left: 40px
        button
          min-width: 140px
      @media (max-width:767px)
        flex-wrap: wrap
        .ld--lugar,.ld--fecha,.ld--acciones
          width: 100%
        .ld--fecha
          margin: 20px 0
        .ld--acciones
          margin-left: 0
          button
            width: 100%

.ld--wrap-precios
  .ld--precios
    margin-top: 32px
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px

    @media (max-width:767px)
      grid-template-columns: repeat(1, 1fr)
    .ld--mapa
      margin-bottom: 32px
      display: flex
      align-items: center
      figure
        padding: 50px
        margin-bottom: 0
        img
          display: block
          max-width: 100%

    .ld--detalles
      padding: 0 0 0 56px
      margin-bottom: 32px
      .ld--item-precio
        display: flex
        justify-content: flex-start
        position: relative
        padding: 16px
        padding-left: 0
        align-items: center
        &:not(:last-child)
          border-bottom: solid 1px #F2F2F2
        .ld--color
          position: absolute
          height: 12px
          width: 12px
          border-radius: 50%
          background: #aaa
          top: 50%
          left: -34px
          transform: translateY(-50%)
        .ld--info
          margin-right: 30px
          .ld--nombre
            font-size: 17px
            line-height: 22px
            font-weight: 500
          .ld--descripcion
            font-size: 15px
            line-height: 20px
            color: var(--color__text__light)
        .ld--precio
          margin-left: auto
          font-size: 17px
          line-height: 22px
          font-weight: 500

    .ld--nota-fees
      font-size: 13px
      margin-bottom: 32px
      margin-top: 10px
      color: var(--color__text__light)

.ld--snacks
  margin-bottom: 32px
  height: 100%
  display: flex
  flex-direction: column
  justify-content: stretch

.ld--merch
  margin-bottom: 32px
  height: 100%
  display: flex
  flex-direction: column
  justify-content: stretch

.ld--item-shop
  height: 100%
  width: 100%
  display: flex
  @media (max-width:1200px)
    flex-direction: column-reverse
    gap: 16px
    &.no_reverse
      flex-direction: column !important
    .ld--btn-shop
      width: 100%
      text-align: center
  .ld--item-wrap
    width: 100%
    height: 100%
    max-width: 700px
    margin: 0 auto
    display: flex
    align-items: center
    padding: 10px 20px
    figure
      display: block
      margin-right: 40px
      img
        display: block
        width: 200px
        margin: 0 auto
        @media (max-width:1200px)
          width: 150px

  .ld--info-item-shop
    height: 100%
    display: flex
    justify-content: center
    flex-direction: column
    .ld--nombre
      font-size: 22px
      line-height: 28px
      font-weight: 700
      margin-bottom: 20px
    .ld--promo
      font-size: 17px
      margin-bottom: 20px
      line-height: 22px
      color: var(--color__text__light)
      strong
        color: var(--color__text)
      span
        text-decoration: line-through
    .ld--btn-shop

.ld--wrap-spotify
  margin-top: 50px
  img
    border-radius: 8px
.player-wrapper
  position: relative
  padding-top: 56.25% // 720 / 1280 = 0.5625 */
  border-radius: 12px
  overflow: hidden
.react-player
  position: absolute
  top: 0
  left: 0
.ld--patrocinantes
  padding: 24px 0
  background: var(--bg__card)
  h3
    color: #B7B7C2
    text-align: center
    font-size: 15px
    line-height: 20px
    font-weight: 400
    margin-bottom: 8px
  .ld--logos
    display: flex
    flex-wrap: wrap
    width: 100%
    justify-content: center
    align-items: center
    gap:20px
    figure
      max-width: 80px
      margin:0
      img
        display: block
        max-height: 45px
        width: 100%

.ld--footer
  margin-top: auto
  padding: 20px 0
  img
    max-height: 60px
  &>div
    display: flex
    justify-content: space-between
    .ld--logo-tm
      margin-left: auto
