#waitingL {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        width: 100%;
        max-width: 500px;
        min-height: 600px;
        background: var(--bg__primary);
        color: 000;
        padding: 40px;
        text-align: center;
        .ico-cola {
            display: block;
            margin: 0 auto 40px;
            width: 100%;
            max-width: 100px;
        }
        h1 {
            font-size: 25px;
        }
        i {
            font-weight: 400;
            display: block;
            margin-bottom: 40px;
        }
        p {
            font-weight: 400;
        }

        //medias queries
        @media (max-width: 767px) {
            max-width: 100%;
            min-height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
