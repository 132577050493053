@import '~bootstrap/scss/bootstrap.scss';
@import '../../../../node_modules/swiper/swiper-bundle.css';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import '../../assets/icons/style.css';
@import '../../assets/styles/variables.scss';



//Commons
body {
    background: #fdfdfd;
    font-size: 14px;
    color: $color1;
}
a,
a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
#aplicationShop {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: $color1;
    background: #F1F2F4;
    padding-top: 80px;
}

#middleLayout {
    width: 100%;
    flex-grow: 1;
}
.paddingY70 {
    padding: 70px 0;
}

.f-hr {
    font-weight: 400;
}
.f-hm {
    font-weight: 600;
}
.f-hb {
    font-weight: bold;
}

.btnGen {
    background: $color6;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 0 20px;
    color: #fff !important;
    transition: ease-in-out all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-width: 120px;
    &::before {
        content: '';
        display: block;
        position: absolute;
        background: #000;
        opacity: 0;
        transition: ease-in-out all 0.2s;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    &:hover::before {
        opacity: 0.1;
    }

    span {
        display: flex;
        position: relative;
        line-height: 14px;
    }
}

.btnOutline {
    height: 40px;
    line-height: 38px;
    color: $color6;
    font-size: 14px;
    text-align: center;
    border: solid 1px $color6;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    span {
        display: flex;
        line-height: 14px;
    }
    &:hover {
        color: $color6;
        border: solid 1px $color6 !important;
    }
}
button,
.btn:focus,
.btn.focus {
    outline: none;
    box-shadow: none;
}

.back {
    cursor: pointer;
}

.textTitle {
    font-size: 24px;
}
.textSubTitle {
    font-size: 18px;
}
.textMedium {
    font-size: 14px;
    color: $color1;
}
.textRegular {
    font-size: 14px;
    color: $color5;
}
.textSec {
    font-size: 12px;
}
//end commons

//boton wtsp
._1bpcM {
    background-color: #4dca5b;
    position: fixed;
    bottom: 60px;
    svg {
        fill: #fff;
    }
    z-index: 99;
}
._2iWL7 {
    bottom: 130px;
    position: fixed;
    z-index: 99;
}
.spinner-custom {
    color: $color6 !important;
}
//Modify INTL
.iti {
    display: block;
}
.iti__flag,
.iti__arrow {
    display: none;
}
.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 0;
}
.iti--separate-dial-code .iti__selected-flag {
    background: none;
    padding: 0 15px;
}
//Modify INTL

//ANIMACIONES
.scaleOff {
    transform: scale(0, 0);
    transition: ease-in-out 0.3s transform;
}
.scaleOn {
    transform: scale(1, 1);
}
//ANIMACIONES

.generalSkeleton {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    .sk-header {
        height: 80px;
        align-items: center;
        display: flex;
        border-bottom: solid 1px $placeholder;
        .sk-logo,
        .sk-car {
            height: 40px;
            width: 100px;
        }
    }
    .sk-banner {
        height: 400px;
        margin-bottom: 50px;
        border-radius: 0;
    }
    .sk-categorias {
        display: flex;
        width: 100%;
        justify-content: space-around;
        & > span {
            width: 100%;
            margin: 0px 10px 50px;
            .sk-cat {
                height: 180px;
            }
        }
    }
    .sk-footer {
        height: 60px;
        border-radius: 0;
    }
}

@import './inputs.scss';
@import './inputCode.scss';
@import './header.scss';
@import './footer.scss';
@import './banner.scss';
@import './home.scss';
@import './categorias.scss';
@import './productos.scss';
@import './confirmacion.scss';
@import './pago.scss';
@import './perfilPedidos.scss';
@import './auth.scss';
