@import '../../styles/themes.sass';
#modalMap {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0 30px;
  @media (max-width: 680px) {
    padding: 0 15px;
  }
  z-index: 9999;
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(#000, 0.7);
  }
  .labelAgotado {
    z-index: 99999;
    transform: translateX(-50%);
    font-family: H-B;
    color: rgb(120, 120, 120);
  }
  .nameZona {
    z-index: 99999;
    transform: translateX(-50%);
    font-family: 'H-B';
    letter-spacing: 0.02em;
    color: #fff;
    font-size: 28px;
    pointer-events: none;
    @media (max-width: 680px) {
      font-size: 18px;
    }
    small {
      font-size: 18px;
      letter-spacing: 0;
      @media (max-width: 680px) {
        font-size: 14px;
      }
    }
  }
}
#modalContent {
  z-index: 10;
  display: block;
  position: relative;
  padding: 30px;
  margin: 30px auto;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 910px;
  h3{
    color:#000
  }
  @media (max-width: 680px) {
    padding: 30px 10px;
  }
  @media (max-width: 992px) {
    max-width: 710px;
  }
  @media (max-width: 767px) {
    max-width: 510px;
  }
  .cerrar {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
    cursor: pointer;
  }
  .infoSec {
    position: absolute;
    left: 30px;
    bottom: 20px;
    & > div {
      font-size: 16px;
      b {
        display: block;
        margin-right: 10px;
      }
    }
  }
  #scroll-svg {
    width: 100%;
    overflow-x: auto;
  }
}

svg#olga {
  width: 100%;
  display: block;
  min-width: 800px
}
#mapa-a {
  display: block;
}

#arena,#arena-1,#arena-2,#arena-3,#arena-4,#vip,#nba-1,#nba-2,#sector-a,#sector-b,#sector-c,#sector-d,#sector-e,#sector-f,#sector-norte,#sector-sur,#sector-f,#gradas
{
  cursor: pointer;
}

.cls-1{fill:#3d512d;}
.cls-2{fill:#9e0202;}
.cls-3,.cls-9{fill:#fff;}
.cls-3{stroke:#b3b3b3;}
.cls-3,.cls-4,.cls-5,.cls-6{stroke-miterlimit:10;}
.cls-4{fill:#b3b3b3;}
.cls-4,.cls-5,.cls-6{stroke:#fff;}
.cls-5{fill:none;}
.cls-6{fill:#e6e6e6;}
.cls-7{fill:#1be5ef;}
.cls-8{fill:#f200ff;}