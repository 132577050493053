.header_fan{
    padding: 21px 0px 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    .rb-img{
        width: 233px;
    }
    p{
        max-width: 1040px;
        font-size: 16px;
    }
    .title_fan{
        font-size: 64px;
        font-weight: 700;
        line-height: 80px;
        letter-spacing: -1.6px;
    }
}

.pricing_cont{
    display: flex;
    width: 100%;
    padding: 0;
    .color_li{
        color: #787b86;
    }
    @media (min-width: 769px) {
        border: 1px solid #363A45;
        border-radius: 20px;
        >li{
            background: rgba(54, 58, 69, 0.2);
            &:nth-of-type(2){
                border-right: 1px solid #363A45;
                border-left: 1px solid #363A45;
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
        >li{
            border: 1px solid #363A45;
            border-radius: 20px;
        }
        
    }
    img{
        height: 80px;
        margin: 0 auto;
    }
    h1{
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 0;
        small{
            font-size: 18px;
            font-weight: 400;
        }
    }
    
    li{
        width: 100%;
        >div{
            display: flex;
            flex-direction: column;
            padding: 50px 24px 30px;
            text-align: center;
            min-height: 340px;
            justify-content: space-between;
            gap: 16px;
            >span{
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            p{
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 0;
                line-height: 36px;
            }
        }
        ul{
            padding: 30px 24px 60px;
            font-weight: 300;
        }
        li{
            min-height: 40px;
            font-size: 16px;
            display: flex;
            gap: 16px;
            align-items: center;
            padding-bottom: 8px;
            span{
                width: calc(100% - 50px);
            }
            /*&:last-of-type{
                opacity: .5;
            }*/
            //border-bottom: 1px solid rgba(255, 255, 255, .2);
        }
    }
    label{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
    }
}
.pricing_cont2{
    display: flex;
    width: 100%;
    padding: 0;
    margin-bottom: 70px;
    @media (min-width: 769px) {
        border: 1px solid #363A45;
        border-radius: 20px;
        >li{
            background: rgba(54, 58, 69, 0.2);
        }
    }
    .title_cont2{
        min-width: 648px;
        li{
            justify-content: flex-start;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
        >li{
            border: 1px solid #363A45;
            border-radius: 20px;
            &:nth-of-type(1){
                display: none;
            }
        }
    }
    h1{
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 0;
    }
    >li{
        background: rgba(54, 58, 69, 0.2);
    }
    li{
        width: 100%;
        text-align: center;
        @media (min-width: 769px) {
            span{
                display: none;
            }
        }
        >div{
            display: flex;
            flex-direction: column;
            padding: 50px 24px 30px;
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            border-bottom: 1px solid #363A45;
            p{
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 0;
                line-height: 36px;
            }
        }
        ul{
            padding: 30px 24px 60px;
            &:first-of-type{
                text-align: left;
            }
        }
        li{
            line-height: 40px;
            font-size: 16px;
            justify-content: center;
            display: flex;
            height: 40px;
            align-items: center;
            //border-bottom: 1px solid rgba(255, 255, 255, .2);
        }
    }
}
.ahor_t{
    font-size: 44px;
    font-weight: bold;
    margin: 70px auto;
    text-align: center;
    
}
.ahor{
    background: rgba(255, 255, 255, .2);
    height: 34px;
    max-width: 210px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 600;
    border-radius: 8px;
    white-space: nowrap;
    gap: 8px;
    position: relative;
    [data-title]:hover:after {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }
    [data-title]:after {
        width: 300px;
        content: attr(data-title);
        white-space: break-spaces !important;
        background-color: #333;
        color: #fff;
        font-size: 14px;
        position: absolute;
        padding: 3px 20px;
        bottom: 40px;
        right: 0;
        box-shadow: 1px 1px 3px #222222;
        opacity: 0;
        font-weight: 400;
        line-height: 1.1;
        border: 1px solid #111111;
        z-index: 99999;
        visibility: hidden;
        border-radius: 6px;
        
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 8px;
    bottom: 3px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(140, 83, 246);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px rgb(140, 83, 246);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }